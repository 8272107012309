import { RouteRecordRaw } from "vue-router";

export const financeRouters: Array<RouteRecordRaw> = [
    {
        path: "/FavouritesHome",
        name: "Favourites",
        component: () => import("@/views/reports/FavouritesHome.vue"),
    },
    //   new ui
    {
        path: "/FixedAssets",
        name: "Fixed assets",
        component: () => import("@/views/FixedAssets/FixedList.vue"),
    },
    {
        path: "/FixedImport",
        name: "Import Fixed assets",
        component: () => import("@/views/FixedAssets/subordinate/FixedImport.vue"),
    },
    {
        path: "/FixedSettings",
        name: "Fixed asset settings",
        component: () => import("@/views/FixedAssets/subordinate/FixedSettings.vue"),
    },
    {
        path: "/AddNewAsset",
        name: "Add New Asset",
        component: () => import("@/views/FixedAssets/subordinate/AddNewAsset.vue"),
    },
    {
        path: "/SettingUpFixedAssets",
        name: "Setting up fixed assets",
        component: () => import("@/views/FixedAssets/subordinate/SettingUpFixedAssets.vue"),
    },
    {
        path: "/RunDepreciation",
        name: "Run Depreciation",
        component: () => import("@/views/FixedAssets/subordinate/RunDepreciation.vue"),
    },

    //reports
    {
        path: "/CashSummary",
        name: "Cash Summary",
        component: () => import("@/views/reports/CashSummary.vue"),
    },
    {
        path: "/GeneralLedgerDetail",
        name: "General Ledger Detail",
        component: () => import("@/views/reports/GeneralLedgerDetail.vue"),
    },
    {
        path: "/JournalReport",
        name: "Journal Report",
        component: () => import("@/views/reports/JournalReport.vue"),
    },
    {
        path: "/SalesTaxReport",
        name: "Sales Tax Report",
        component: () => import("@/views/reports/SalesTaxReport.vue"),
    },
    {
        path: "/GeneralLedgerExceptions",
        name: "General Ledger Exceptions",
        component: () => import("@/views/reports/GeneralLedgerExceptions.vue"),
    },
    {
        path: "/TaxReconciliation",
        name: "Tax Reconciliation",
        component: () => import("@/views/reports/TaxReconciliation.vue"),
    },
    {
        path: "/GeneralLedgerSummary",
        name: "General Ledger Summary",
        component: () => import("@/views/reports/GeneralLedgerSummary.vue"),
    },
    {
        path: "/TrialBalance",
        name: "Trial Balance",
        component: () => import("@/views/reports/TrialBalance.vue"),
    },
    {
        path: "/AwaitingPayment",
        name: "Awaiting Payment",
        component: () => import("@/views/reports/AwaitingPayment.vue"),
    },
    {
        path: "/AgedPayablesSummary",
        name: "Aged Payables Summary",
        component: () => import("@/views/reports/AgedPayablesSummary.vue"),
    },
    {
        path: "/AgedReceivablesSummary",
        name: "Aged Receivables Summary",
        component: () => import("@/views/reports/AgedReceivablesSummary.vue"),
    },
    {
        path: "/BankAccounts",
        name: "Bank accounts",
        component: () => import("@/views/reports/BankAccounts.vue"),
    },
    {
        path: "/AccountTransactions",
        name: "Account Transactions",
        component: () => import("@/views/reports/AccountTransactions.vue"),
    },
    {
        path: "/ProfitandLoss",
        name: "Profit and Loss",
        component: () => import("@/views/reports/ProfitandLoss.vue"),
    },
    {
        path: "/ChartOfAccounts",
        name: "Chart of accounts",
        component: () => import("@/views/FixedAssets/ChartOfAccounts.vue"),
    },
    {
        path: "/PurchaseOrders",
        name: "Purchase Orders",
        component: () => import("@/views/FixedAssets/PurchaseOrders.vue"),
    },
    {
        path: "/BillsToPay",
        name: "Bills to pay",
        component: () => import("@/views/reports/BillsToPay.vue"),
    },
    {
        path: "/InvoicesList",
        name: "Invoices Table",
        component: () => import("@/views/reports/InvoicesList.vue"),
    },
    {
        path: "/TaxRates",
        name: "Tax Rates",
        component: () => import("@/views/reports/TaxRates/TaxRates.vue"),
    },
    {
        path: "/TopSuppliers",
        name: "Top suppliers",
        component: () => import("@/views/reports/TopSuppliers/TopSuppliers.vue"),
    },
    {
        path: "/ExpenseClaimDetail",
        name: "Expense Claim Detail",
        component: () => import("@/views/reports/ExpenseClaimDetail.vue"),
    },
].map((item: any) => {
    item["meta"] = {
        nonAuth: true,
    };
    return item;
});
